/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { Link } from "gatsby";

import GoBack from "../../../../assets/images/svg/go-back.svg";

import { itens } from "./data";
import Table from "./Table";

const MaterialsAndFood = ({ translation, fontDefault }) => {
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0 20px 80px;
      `}
    >
      <header
        css={css`
          position: relative;
        `}
      >
        <Link
          href="/"
          css={css`
            position: absolute;
            left: 0;
          `}
        >
          <GoBack />
        </Link>

        <h2
          style={{
            color: "#203760",
            marginBottom: "43px",
            textAlign: "center",
          }}
        >
          Materiais e alimentos
        </h2>
      </header>

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
     

        <div
          css={css`
            align-items: center;
            justify-content: center;
            align-self: center;
            margin-top: 30px;
          `}
        >
                  <Table title={itens[0].title} data={itens[0]} />
          <div>
            <Table title={itens[1].title} data={itens[1]} />
            <Table
              title={itens[2].title}
              data={itens[2]}
              observation={itens[2].observation}
            />
          </div>
        </div>
     
      </div>
    </section>
  );
};

export default MaterialsAndFood;
