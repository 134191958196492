/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import MaterialsAndFood from "../components/pages/Home/MaterialsAndFood"
import MaterialsAndFoodMobile from "../components/pages/Home/MaterialsAndFood/mobile"

import { isMobile, isTablet } from "react-device-detect"

const DonateMaterialsAndFood = ({ pageContext: translation }) => {
  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />

      {isMobile && isTablet ? (
        <MaterialsAndFoodMobile fontDefault={true} translation={translation} />
      ) : isMobile ? <MaterialsAndFoodMobile fontDefault={true} translation={translation} /> : (
        <MaterialsAndFood fontDefault={true} translation={translation} />
      )}
    </Layout>
  )
}

export default DonateMaterialsAndFood
